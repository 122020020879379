import React from 'react';
import cx from 'clsx';
import useResizeObserver from 'use-resize-observer';
import { createUseStyles } from 'react-jss';
import { pxToRem, breakpoint } from 'src/theme';
import SectionContainer, { Props } from 'src/sections/shared/SectionContainer';
import DashboardVideo from 'src/components/DashboardVideo';

// Used to approximate how tall the video will be and specify padding
const videoWidthToHeightRatio = 0.65;
const videoHeightOffset = 0.35;
const videoMaxWidth = pxToRem(680);

const verticalLargePadding = `calc(min(${videoMaxWidth}, 50vw) * ${
  videoWidthToHeightRatio
} * ${
  videoHeightOffset
})`;
const verticalSmallPadding = `calc(min(${videoMaxWidth}, 95vw) * ${
  videoWidthToHeightRatio
} * ${
  videoHeightOffset
})`;

export const useSectionAboveStyles = createUseStyles({
  root: {
    paddingBottom: verticalLargePadding,
    [breakpoint.down('md')]: {
      paddingBottom: verticalSmallPadding,
      paddingTop: 0,
    },
  },
});

const useStyles = createUseStyles({
  container: {
    overflow: 'visible',
    background: '#F7F7F7',
    // CSS trick so that we can have a video which is not absolute positioned
    // and still overlap with the above section
    paddingTop: '1px !important',
  },
  video: {
    maxWidth: videoMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50vw',
    [breakpoint.down('md')]: {
      width: '95vw',
    },
  },
});

const SectionWithVideoOverlap: React.FC<Props> = ({
  children,
  className,
  ...sectionContainerProps
}) => {
  const classes = useStyles();
  const {
    height: videoHeight = 0,
    ref: videoRef,
  } = useResizeObserver<HTMLDivElement>();
  return (
    <SectionContainer className={cx(classes.container, className)} {...sectionContainerProps}>
      <DashboardVideo
        className={classes.video}
        ref={videoRef}
        style={{ marginTop: -videoHeight * videoHeightOffset }}
      />
      {children}
    </SectionContainer>
  );
};

export default SectionWithVideoOverlap;
