import React from 'react';
import { assetLink } from 'src/utils';
import AutoplayVideo from 'src/components/AutoplayVideo';
import VideoContainer from 'src/images/video-containers/Rectangle2.svg';

interface Props {
  style?: React.CSSProperties,
  className?: string,
}

const DashboardVideo = React.forwardRef<HTMLDivElement, Props>(({
  className,
  style,
}, ref) => (
  <div style={{ position: 'relative', ...style }} className={className} ref={ref}>
    <AutoplayVideo
      src={assetLink('landing-ui/videos/DashboardAnimationsCompressed.mp4')}
      style={{
        position: 'absolute',
        width: '91%',
        top: '4%',
        left: '5%',
      }}
    />
    <VideoContainer style={{ width: '100%', height: '100%' }} />
  </div>
));

export default DashboardVideo;
